exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industriuka-privacy-tsx": () => import("./../../../src/pages/industriuka-privacy.tsx" /* webpackChunkName: "component---src-pages-industriuka-privacy-tsx" */),
  "component---src-pages-industriuka-terms-tsx": () => import("./../../../src/pages/industriuka-terms.tsx" /* webpackChunkName: "component---src-pages-industriuka-terms-tsx" */),
  "component---src-pages-mapheim-privacy-tsx": () => import("./../../../src/pages/mapheim-privacy.tsx" /* webpackChunkName: "component---src-pages-mapheim-privacy-tsx" */),
  "component---src-pages-mapheim-terms-tsx": () => import("./../../../src/pages/mapheim-terms.tsx" /* webpackChunkName: "component---src-pages-mapheim-terms-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-redirect-by-id-tsx": () => import("./../../../src/pages/redirectById.tsx" /* webpackChunkName: "component---src-pages-redirect-by-id-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-post-detail-tsx": () => import("./../../../src/templates/blogPostDetail.tsx" /* webpackChunkName: "component---src-templates-blog-post-detail-tsx" */)
}

